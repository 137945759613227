<template>
    <div>
My Integrations
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="postcss" scoped>

</style>